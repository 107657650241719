<template>
    <div class="SalesMakers Wrapper-section" id="SalesMakersPage">
        <div class="Trigger TriggerTop" id="SalesMakersTriggerTop"></div>
        <div class="SalesMakersPanel" id="SalesMakers">
            <div class="SalesMakers-container">
                <h1 class="title SalesMakers-title">The (un)usual suspects delivering out-sized results.</h1>
            </div>
            <TeamSlider />
        </div>
        <div class="Trigger TriggerBottom" id="SalesMakersTriggerBottom"></div>
    </div>
</template>

<script>
    export default {
        name: 'SalesMakers',
        data() {
            return {
                sceneTimer: null,
                scrollMagicTrigger: ['SalesMakersTriggerTop'],
                scrollMagicSectionId: ['SalesMakersPage']
            };
        },
        components: {
            'TeamSlider': () => import('@/components/TeamSlider')
        },
        computed: {},
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 1000);
            },
            createScene() {
                clearInterval(this.sceneTimer);
                const SMC = new this.$scrollmagic.Controller();

                for (let i = 0; i < this.scrollMagicSectionId.length; i++) {
                    const scene = new this.$scrollmagic.Scene({
                        triggerElement: `#${this.scrollMagicTrigger[i]}`,
                        triggerHook: 'onEnter'
                    }).setClassToggle(`#${this.scrollMagicSectionId[i]}`, 'visible'); // add class to reveal
                    SMC.addScene(scene);
                }
            }
        }
    };
</script>
